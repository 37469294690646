import { SVLRouteValue } from '@domain/shared/svl-route-value';
import { AppRouteNames } from 'src/app/app.routenames';

interface CoreRouteNamesType {
  home: SVLRouteValue;
  faq: SVLRouteValue;
  login: SVLRouteValue;
  contract: SVLRouteValue;
  recoverPassword: SVLRouteValue;
}

export const CoreRouteNames: CoreRouteNamesType = {
  home: {
    local: '',
    global: AppRouteNames.core,
  },
  faq: {
    local: 'faq',
    global: `${AppRouteNames.core}/faq`,
  },
  login: {
    local: 'login',
    global: `${AppRouteNames.core}/login`,
  },
  contract: {
    local: 'contract',
    global: `${AppRouteNames.core}/contract`,
  },
  recoverPassword: {
    local: 'recover-password',
    global: AppRouteNames.core + '/recover-password',
  },
};
